/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/19
* @description
*   TheCommissionDirection.vue of WeTrade
*/
<template>
  <div>
    <t as="p" path="symbol_8" class="c-text f-nm f-bold f-md">Direction</t>
    <div class="mt-16 d-f">
      <Button class="flex-1 br-lg" :class="{success: isBuy}" sm @click="buy">
        <t path="symbol_9" custom #="{td}">{{td || 'Buy'}}</t>
      </Button>
      <Button class="flex-1 ml-8 br-lg"  :class="{danger: !isBuy}" sm @click="sell">
        <t path="symbol_10" custom #="{td}">{{td || 'Sell'}}</t>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import TradeModel from '@/modules/trade/TradeModel'
import { TradeDirection } from '@/types'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheCommissionDirection',
  components: { Button },
  setup () {
    const model = inject('model') as TradeModel
    const isBuy = computed(() => model.getValue().type === TradeDirection.BUY)

    return {
      isBuy,
      buy () {
        model.setType(TradeDirection.BUY)
      },
      sell () {
        model.setType(TradeDirection.SELL)
      },
    }
  },
})
</script>
