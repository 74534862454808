
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import TheCommissionDirection from '@/modules/trade/components/TheCommissionDirection.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradePaymentMethods from '@/modules/trade/components/TheTradePaymentMethods.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import TheEditCommissionButton from '@/modules/trade/components/TheEditCommissionButton.vue'
import { Commission, readCommission, readProduct } from '@/modules/trade/trade.api'
import { calcProduct } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, provide, shallowReadonly, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import QuoteBar from '@/components/QuoteBar.vue'
import { mul } from 'essential/tools/math'
import { TradeDirection, YesOrNo } from '@/types'

const dynamicSetting = {
  [TradeDirection.BUY]: {
    label: 'BUY',
    themeClass: 'success',
  },
  [TradeDirection.SELL]: {
    label: 'SELL',
    themeClass: 'danger',
  },
}

export default defineComponent({
  name: 'Edit',
  components: {
    TheTakeProfit,
    TheStopLoss,
    TheCommissionDirection,
    HoldOn,
    TheEditCommissionButton,
    Button,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheTradePaymentMethods,
    TheTradeTargetPrice,
    TheTradeProducts,
    PageWithHeader,
    QuoteBar,
  },
  setup () {
    const route = useRoute()
    const order = shallowRef<Commission | null>(null)
    const model = new TradeModel(TradeDirection.BUY)
    const often = [1, 10, 20, 50, 100, 200, 500, 1000]

    readCommission({
      orderId: route.params.orderId,
    })
      .then(commission => {
        model.setType(commission.type)
        model.setOrderId(commission.orderId)
        model.setPrice(commission.entrustPrice)
        model.setTopLimit(commission.stopProfitPoint)
        model.setBottomLimit(commission.stopLossPoint)
        model.useCoupon(Boolean(commission.isJuan))
        readProduct({ productId: commission.productId })
          .then(resp => {
            const dot = Number(String(commission.orderNumber).substring(0, 1))
            const mulNum = Math.pow(10, commission.orderNumber.toString().length - 1)
            const curProduct = calcProduct(mulNum, dot, resp)
            curProduct.isOften = often.indexOf(mul(mulNum, dot)) > -1 ? YesOrNo.YES : YesOrNo.NO
            model.updateProduct(curProduct)
            order.value = commission
          })
      })

    const themeClass = dynamicSetting[1].themeClass

    provide('themeClass', themeClass)
    provide('model', shallowReadonly(model))

    return {
      order,
    }
  },
})
