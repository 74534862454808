/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/18
* @description
*   Edit.vue of WeTrade
*/

<template>
<PageWithHeader class="create">
  <template #title>
    <t path="symbol_50" class="f-lg">Modify Limit Order</t>
  </template>
  <QuoteBar v-if="order" :code="order.code" class="px-16" />
  <HoldOn :content="order" class="px-16">
    <TheCommissionDirection class="mt-24" />
    <TheTradeProducts class="mt-32" />
    <!-- <Line class="my-16" /> -->
    <TheTradeTargetPrice class="mt-32" />
    <!-- <Line class="my-16" /> -->
    <TheTradePaymentMethods class="mt-32" />
    <Line class="my-16" />
    <TheTradeLimits />
    <Line class="my-16" />
    <TheStopLoss :value="order.stopLossPoint" />
    <Line class="my-16" />
    <TheTakeProfit :value="order.stopProfitPoint" />
    <Line class="my-16" />
    <TheTradeCost class="pb-24" />
    <div class="controls d-f f-bold f-lg">
      <Button class="flex-1" @click="$router.back()">
        <t path="symbol_37" #="{td}" custom>{{td || 'Cancel'}}</t>
      </Button>
      <div class="flex-2">
        <TheEditCommissionButton />
      </div>
    </div>
  </HoldOn>
</PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Line from '@/components/Line.vue'
import TheCommissionDirection from '@/modules/trade/components/TheCommissionDirection.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'
import TheTradeCost from '@/modules/trade/components/TheTradeCost.vue'
import TheTradeLimits from '@/modules/trade/components/TheTradeLimits.vue'
import TheTradePaymentMethods from '@/modules/trade/components/TheTradePaymentMethods.vue'
import TheTradeProducts from '@/modules/trade/components/TheTradeProducts.vue'
import TheTradeTargetPrice from '@/modules/trade/components/TheTradeTargetPrice.vue'
import TheEditCommissionButton from '@/modules/trade/components/TheEditCommissionButton.vue'
import { Commission, readCommission, readProduct } from '@/modules/trade/trade.api'
import { calcProduct } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import HoldOn from '@/provider/HoldOn.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, provide, shallowReadonly, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import QuoteBar from '@/components/QuoteBar.vue'
import { mul } from 'essential/tools/math'
import { TradeDirection, YesOrNo } from '@/types'

const dynamicSetting = {
  [TradeDirection.BUY]: {
    label: 'BUY',
    themeClass: 'success',
  },
  [TradeDirection.SELL]: {
    label: 'SELL',
    themeClass: 'danger',
  },
}

export default defineComponent({
  name: 'Edit',
  components: {
    TheTakeProfit,
    TheStopLoss,
    TheCommissionDirection,
    HoldOn,
    TheEditCommissionButton,
    Button,
    TheTradeLimits,
    Line,
    TheTradeCost,
    TheTradePaymentMethods,
    TheTradeTargetPrice,
    TheTradeProducts,
    PageWithHeader,
    QuoteBar,
  },
  setup () {
    const route = useRoute()
    const order = shallowRef<Commission | null>(null)
    const model = new TradeModel(TradeDirection.BUY)
    const often = [1, 10, 20, 50, 100, 200, 500, 1000]

    readCommission({
      orderId: route.params.orderId,
    })
      .then(commission => {
        model.setType(commission.type)
        model.setOrderId(commission.orderId)
        model.setPrice(commission.entrustPrice)
        model.setTopLimit(commission.stopProfitPoint)
        model.setBottomLimit(commission.stopLossPoint)
        model.useCoupon(Boolean(commission.isJuan))
        readProduct({ productId: commission.productId })
          .then(resp => {
            const dot = Number(String(commission.orderNumber).substring(0, 1))
            const mulNum = Math.pow(10, commission.orderNumber.toString().length - 1)
            const curProduct = calcProduct(mulNum, dot, resp)
            curProduct.isOften = often.indexOf(mul(mulNum, dot)) > -1 ? YesOrNo.YES : YesOrNo.NO
            model.updateProduct(curProduct)
            order.value = commission
          })
      })

    const themeClass = dynamicSetting[1].themeClass

    provide('themeClass', themeClass)
    provide('model', shallowReadonly(model))

    return {
      order,
    }
  },
})
</script>

<style scoped>
.create {
  padding-bottom: 48px;
}

.controls {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
