
import Button from '@/components/Button.vue'
import TradeModel from '@/modules/trade/TradeModel'
import { TradeDirection } from '@/types'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'TheCommissionDirection',
  components: { Button },
  setup () {
    const model = inject('model') as TradeModel
    const isBuy = computed(() => model.getValue().type === TradeDirection.BUY)

    return {
      isBuy,
      buy () {
        model.setType(TradeDirection.BUY)
      },
      sell () {
        model.setType(TradeDirection.SELL)
      },
    }
  },
})
