/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/18
* @description
*   TheEditCommissionButton.vue of WeTrade
*/
<template>
  <Button class="primary block" @click="submit" :progress="progress">
    <t path="placeorder_19" #="{td}" custom>{{ td || 'Place order' }}</t>
  </Button>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { editCommission } from '@/modules/trade/trade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEditCommissionButton',
  components: { Button },
  setup () {
    const [submit, progress] = editCommission()

    return {
      submit,
      progress,
    }
  },
})
</script>
